import clsx from 'clsx'
import React from 'react'

type Props = React.PropsWithChildren<{
  tag?: keyof JSX.IntrinsicElements
  padding?: 'none' | 'small' | 'large'
  className?: string
  style?: React.CSSProperties
}>

const Panel = React.forwardRef<HTMLElement, Props>(({
  tag: Tag = 'div',
  padding = 'large',
  className,
  style,
  children,
}, ref) => {
  const classes = clsx(
    'bg-slate-50/60 text-slate-800 rounded-xl shadow-lg',
    className,
    {
      'p-4 sm:p-8': padding === 'large',
      'p-4': padding === 'small',
    },
  )
  return (
    // @ts-ignore
    <Tag className={classes} ref={ref} style={style}>{children}</Tag>
  )
})

Panel.displayName = 'Panel'

export default Panel
